import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { TopBar } from "./data/datahero";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  const [movies, setMovies] = useState(TopBar);
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10 inset-x-0 top-[20%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8 ">
        <span className="sm:text-lg md:text-xl font-semibold text-neutral-100">
          Lokalai is a flatform to offers
        </span>
        <h2 className="font-bold text-white text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl !leading-[115%] ">
          All The <br /> Best Deals
        </h2>
        <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Explore What's On
        </ButtonPrimary>
      </div>
      <div className="relative round-xl">
        <Swiper
          spaceBetween={0}
          navigation={false}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          speed={2000}
          modules={[Autoplay]}
          slidesPerView={1}
          className="swiper_hero"
          direction={"vertical"}
          loop={true}
        >
          {movies.map((movie, index) => (
            <SwiperSlide key={index}>
              <div
                className="
        relative 
        bg-center 
        bg-cover 
        brightness-50
                rounded-xl
        overflow-hidden
      "
                style={{
                  backgroundImage: `url("${movie.img}")`,
                }}
              >
                {/* Responsive padding */}
                <div
                  className="
                  pt-[130%] 
                  sm:pt-[180%]
          sm:pt-[100%] 
          md:pt-[120%] 
          lg:pt-[60%]
        "
                ></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SectionHero3;
