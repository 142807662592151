import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import ItemCard from "components/ItemCard/ItemCard";
import StayCard from "components/StayCard/StayCard";
import couponItemApi from "api/couponItem/couponItem.api";
import Loading from "components/Loading/Loading";
import { StayDataType } from "data/types";
import e from "socket.io-client";
import axios from "axios";
import { baseURL } from "configs/axiosConfig";

const DEMO_DATA = DEMO_STAY_LISTINGS.filter((_, i) => i < 20);

interface CouponItem {
  id: string;
  title: string;
  image: string;
}

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const tabIds = {
  "Mako Recommend": "65f0f2ba581d486f06bb9c67",
  "Trending Now": "65f0f2ba581d486f06bb9c68",
  "Deal You Can't Miss": "65f0f2ba581d486f06bb9c69",
  Update: "update-choices-tab",
  "Ending Soon": "65f0f2ba581d486f06bb9c6a",
  "Smart Choices": "smart-choices-tab",
} as const;

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Highlights",
  subHeading = "The best deals that Lokalai recommends for you",
  headingIsCenter,
  tabs = [
    "Update",
    "Mako Recommend",
    "Trending Now",
    "Deal You Can't Miss",
    "Ending Soon",
    "Smart Choices",
  ],
}) => {
  const [couponItems, setCouponItems] = useState<CouponItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("Update");

  const getCouponItems = async (tab: string) => {
    setLoading(true);
    try {
      if (tabIds[tab as keyof typeof tabIds]) {
        if (tab === "Smart Choices") {
          setActiveTab(tab);
          setLoading(false);
          return;
        }

        let response;

        if (tab === "Update") {
          response = await axios.get(`${baseURL}/api/products`);
        } else if (tab !== "Update") {
          response = await couponItemApi.getCouponItem({
            _id: tabIds[tab as keyof typeof tabIds],
          });
        }

        console.log("response Update", response.data);

        if (response.success && response.couponItems) {
          const items: CouponItem[] = response.couponItems.map((item: any) => ({
            title: item.title,
            image: item.images,
            id: item._id,
          }));
          setCouponItems(items);
        } else if (response.data) {
          const itemsupdate: CouponItem[] = response.data
            .reverse()
            .map((item: any) => ({
              title: item.title,
              image: item.images,
              id: item._id,
            }));
          setCouponItems(itemsupdate);
        } else {
          console.error("Unexpected response structure:", response);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      if (tab !== "Smart Choices") setLoading(false);
    }
  };

  useEffect(() => {
    getCouponItems(activeTab);
  }, [activeTab]);

  const handleClickedTab = (tab: string) => {
    setActiveTab(tab);
    getCouponItems(tab);
  };

  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={activeTab}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={handleClickedTab}
      />

      {loading ? (
        <div className="flex items-center justify-center min-h-[300px]">
          <Loading />
        </div>
      ) : (
        <div
          className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
        >
          {activeTab === "Smart Choices" ? (
            DEMO_DATA.map((stay) => renderCard(stay))
          ) : couponItems.length > 0 ? (
            couponItems.map((item) => (
              <ItemCard
                key={item.title}
                title={item.title}
                galleryImgs={[item.image]}
                id={item.id}
              />
            ))
          ) : (
            <div>No items found.</div>
          )}
        </div>
      )}

      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading={false}>Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;

// --------------------------------- Bỏ smart choices ------------------------------
// import React, { FC, ReactNode, useEffect, useState } from "react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import HeaderFilter from "./HeaderFilter";
// import ItemCard from "components/ItemCard/ItemCard";
// import couponItemApi from "api/couponItem/couponItem.api";
// import Loading from "components/Loading/Loading";

// const DEMO_DATA = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

// interface CouponItem {
//   title: string;
//   image: string;
// }

// export interface SectionGridFeaturePlacesProps {
//   stayListings?: any[];
//   gridClass?: string;
//   heading?: ReactNode;
//   subHeading?: ReactNode;
//   headingIsCenter?: boolean;
//   tabs?: string[];
// }

// const tabIds = {
//   "Mako Recommend": "65f0f2ba581d486f06bb9c67",
//   "Trending Now": "65f0f2ba581d486f06bb9c68",
//   "Deal You Can't Miss": "65f0f2ba581d486f06bb9c69",
//   "Ending Soon": "65f0f2ba581d486f06bb9c6a",
// } as const;

// const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
//   stayListings = DEMO_DATA,
//   gridClass = "",
//   heading = "Highlights",
//   subHeading = "The best deals that Lokalai recommends for you",
//   headingIsCenter,
//   tabs = ["Mako Recommend", "Trending Now", "Deal You Can't Miss", "Ending Soon"],
// }) => {
//   const [couponItems, setCouponItems] = useState<CouponItem[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);

//   const getCouponItems = async (tab: string) => {
//     setLoading(true);
//     try {
//       if (tabIds[tab as keyof typeof tabIds]) {
//         const response = await couponItemApi.getCouponItem({ _id: tabIds[tab as keyof typeof tabIds] });

//         if (response.success && response.couponItems) {
//           const items: CouponItem[] = response.couponItems.map((item: any) => ({
//             title: item.title,
//             image: item.images,
//           }));
//           setCouponItems(items);
//         }
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getCouponItems("Mako Recommend");
//   }, []);

//   const handleClickedTab = (tab: string) => {
//     getCouponItems(tab);
//   };

//   return (
//     <div className="nc-SectionGridFeaturePlaces relative">
//       <HeaderFilter
//         tabActive={"Smart Choices"}
//         subHeading={subHeading}
//         tabs={tabs}
//         heading={heading}
//         onClickTab={handleClickedTab}
//       />

//       {loading ? (
//         <div className="flex items-center justify-center">
//           <Loading />
//         </div>
//       ) : (
//         <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}>
//           {couponItems.length > 0 ? (
//             couponItems.map(item => (
//               <ItemCard key={item.title} title={item.title} galleryImgs={[item.image]} />
//             ))
//           ) : (
//             <div>No items found.</div>
//           )}
//         </div>
//       )}

//       <div className="flex mt-16 justify-center items-center">
//         <ButtonPrimary loading={false}>Show me more</ButtonPrimary>
//       </div>
//     </div>
//   );
// };

// export default SectionGridFeaturePlaces;
