import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation } from "react-router-dom";
import menudot from "images/logos/lokalai/menudot.svg";

export interface MenuBarProps {
  className?: string;
  iconClassName?: string;
}
const MenuBar: React.FC<MenuBarProps> = ({
  className = "p-2.5 text-neutral-700 dark:text-neutral-300 ",
  iconClassName = "h-7 w-7",
}) => {
  const [isVisable, setIsVisable] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsVisable(false);
  }, [location]);

  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => setIsVisable(false);

  const renderContent = () => {
    return (
      <Transition appear show={isVisable} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 overflow-hidden"
          onClose={handleCloseMenu}
        >
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex justify-end min-h-full ">
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 translate-x-56"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-56"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden transition-all ">
                  <NavMobile onClickClose={handleCloseMenu} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <div
        onClick={handleOpenMenu}
        className={`focus:outline-none flex items-center justify-center ${className}`}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className={iconClassName}
          viewBox="0 0 20 20"
          fill="#ff0000"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg> */}
        <svg
          width="800px"
          height="800px"
          viewBox="0 0 1024 1024"
          className={iconClassName}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M130.735 253.626m-66.735 0a66.735 66.735 0 1 0 133.47 0 66.735 66.735 0 1 0-133.47 0Z"
            fill="#000000"
          />
          <path
            d="M130.735 503.434m-66.735 0a66.735 66.735 0 1 0 133.47 0 66.735 66.735 0 1 0-133.47 0Z"
            fill="#FF0000"
          />
          <path
            d="M130.735 770.374m-66.735 0a66.735 66.735 0 1 0 133.47 0 66.735 66.735 0 1 0-133.47 0Z"
            fill="#000000"
          />
          <path
            d="M913.385 283.49H351.807c-16.494 0-29.867-13.373-29.867-29.867s13.373-29.867 29.867-29.867h561.578c16.494 0 29.867 13.373 29.867 29.867s-13.373 29.867-29.867 29.867zM913.385 533.304H351.807c-16.494 0-29.867-13.373-29.867-29.867s13.373-29.867 29.867-29.867h561.578c16.494 0 29.867 13.373 29.867 29.867 0 16.493-13.373 29.867-29.867 29.867zM930.133 800.238H368.564c-16.494 0-29.867-13.373-29.867-29.867s13.373-29.867 29.867-29.867h561.569c16.494 0 29.867 13.373 29.867 29.867s-13.373 29.867-29.867 29.867z"
            fill="#000000"
          />
        </svg>
      </div>

      {renderContent()}
    </>
  );
};

export default MenuBar;
